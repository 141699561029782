import { BrowserRouter,Routes ,Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.js";
import Login from '../pages/Login'
import LayoutOutlet from './LayoutOutlet'
import Dashboard from '../pages/Dashboard';
import BlogList from '../pages/BlogList';
import BlogPage from '../pages/BlogPage';
import UserList from '../pages/UserList';
import AdminUserList from '../pages/AdminUserList';
import RolesPage from '../pages/RolesPage';
import Unauthorized from '../pages/Unauthorized';
import AffiliateList from '../pages/AffiliateList';
import AffiliateProfile from '../pages/AffiliateProfile';
import LeadsList from '../pages/LeadsList';
import AdminUserProfile from '../pages/AdminUserProfile';
import MarketingForm from '../pages/MarketingFormList';
import FeedbackList from '../pages/FeedbackList';
import FreeAiTools from '../pages/FreeAiTools';
import AiToolPage from '../pages/AiToolPage';
import MissedUser from '../pages/MissedUser';
import WaitList from '../pages/WaitList';
import PopupForms from '../pages/PopupForms';
import DemoForms from '../pages/DemoForms';
import BookDemo from '../pages/BookDemo';
import Token from '../pages/Token';
function Routing() {
    return (<>
        <BrowserRouter>
            <Routes>
                <Route index  element={<Login />}></Route>
                <Route path="/" element={<LayoutOutlet />}>
                    <Route path='/dashboard' element={<Dashboard />}/>
                    <Route path='/blog/blog-list' element={<BlogList />}/>
                    <Route path='/missed-user' element={<MissedUser />}/>
                    <Route path='/book-demo' element={<BookDemo />}/>
                    <Route path='/token' element={<Token />}/>
                    <Route path='/wait-list' element={<WaitList />}/>
                    <Route path='/popup-forms' element={<PopupForms />}/>
                    <Route path='/demo-forms' element={<DemoForms />}/>
                    <Route path='/blog/create/:id' element={<BlogPage />}/>
                    <Route path='/subscribers' element={<UserList />}/>
                    <Route path='/affiliates' element={<AffiliateList />}/>
                    <Route path='/leads' element={<LeadsList />}/>
                    <Route path='/my-profile' element={<AdminUserProfile />}/>
                    <Route path='/marketing-form' element={<MarketingForm />}/>
                    <Route path='/feedback' element={<FeedbackList />}/>
                    <Route path='/free_ai_tools' element={<FreeAiTools />}/>
                    <Route path='/free_ai_tools/:id' element={<AiToolPage />}/>
                    <Route path='/affiliates/:aff_id' element={<AffiliateProfile />}/>
                    <Route path='/users/admin-list' element={<AdminUserList />}/>
                    <Route path='/users/user-roles' element={<RolesPage />}/>
                </Route>
                <Route path="/unauthorized" element={<Unauthorized />}></Route>
            </Routes>
        </BrowserRouter>
    </>)
}

export default Routing;
