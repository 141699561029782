/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import {NavLink, useLocation} from "react-router-dom";
import useBaseMatch from '../hooks/useBaseMatch';
import useCommonApi from '../hooks/common-api';

function AsideBar() {
    const [accessMenus, setAccessMenus] = useState([])
    const moreLink = (env, id) => {
        if (!env.target.matches('.active')) {
            document.querySelectorAll('.nav-toggle').forEach((e)=>{
                e.classList.remove('active');
            });
            document.querySelectorAll('.navmore-link').forEach((e)=>{
                e.classList.remove('active');
            });
            env.target.classList.add('active');
            document.getElementById(id).classList.add('active');
        }
        else{
            env.target.classList.remove('active');
            document.getElementById(id).classList.remove('active');  
        }
    }
    const menuToggle = () => {
        document.body.classList.toggle('shrink-menu');
    }
    // const adminListMenu = useMatch('/users/admin-list');
    // const rolesMenu = useMatch('/users/user-roles');
    // const userMenuActive = adminListMenu || rolesMenu;
    const userMenuActive = useBaseMatch('/users')
    const blogMenuActive = useBaseMatch('/blog')
    const freeAiToolsMenuActive = useBaseMatch('/free_ai_tools')
    const missedUser = useBaseMatch('/missed-user');
    const token = useBaseMatch('/token');
    const bookDemo = useBaseMatch('/book-demo');
    const waitList = useBaseMatch('/wait-list');
    const popupForms = useBaseMatch('/popup-forms');
    const {state} = useLocation()
    const {getModuleAccessList } = useCommonApi()
    
    function checkModuleAccess(module_key){
        if(state?.is_admin) return true
        return accessMenus?.includes(module_key)
    }

    useEffect(() => {
        if(state?.is_admin) return
      getModuleAccessList(setAccessMenus);
    }, [])
    console.log(accessMenus,state, "accessMenus")
    
  return (
    <>
    <div className='aside-new' id="asideBar">
        <div className='d-flex px-3 pt-2'>
            <button type="button" className='ms-auto menu-btn' onClick={()=>menuToggle()}>
                <svg className="icon">
                    <use href="#icon_menu"></use>
                </svg>
            </button>
        </div>
        <ul>
           {checkModuleAccess('dashboard') && <li>
                <NavLink className="nav-link" to="/dashboard" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_dashboard"></use>
                        </svg>
                    </span>
                    <strong>Dashboard</strong>
                </NavLink>
            </li>}
            {checkModuleAccess('subscribers') &&<li>
                <NavLink className="nav-link" to="/subscribers" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_subscribers"></use>
                        </svg>
                    </span>
                    <strong>Subscribers</strong>
                </NavLink>
            </li>}
            {checkModuleAccess('affiliates') &&<li>
                <NavLink className="nav-link" to="/affiliates" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_affiliate_marketing"></use>
                        </svg>
                    </span>
                    <strong>Affiliates</strong>
                </NavLink>
            </li>}
            {checkModuleAccess('leads') &&<li>
                <NavLink className="nav-link" to="/leads" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_leads"></use>
                        </svg>
                    </span>
                    <strong>Leads</strong>
                </NavLink>
            </li>}
            {checkModuleAccess('token') &&<li>
                <NavLink className={"nav-link " + (token  ? ' active' : '')} to="/token" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_shield"></use>
                        </svg>
                    </span>
                    <strong>Token</strong>
                </NavLink>
            </li>}
            {checkModuleAccess('missed_user') &&<li>
                <NavLink className={"nav-link " + (missedUser  ? ' active' : '')} to="/missed-user" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_missed_user"></use>
                        </svg>
                    </span>
                    <strong>Missed User</strong>
                </NavLink>
            </li>}
            {checkModuleAccess('book_demo') &&<li>
                <NavLink className={"nav-link " + (bookDemo  ? ' active' : '')} to="/book-demo" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_calendar"></use>
                        </svg>
                    </span>
                    <strong>Book Demo</strong>
                </NavLink>
            </li>}
            {checkModuleAccess('wait_list') &&<li>
                <NavLink className={"nav-link " + (waitList  ? ' active' : '')} to="/wait-list" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_wait_list"></use>
                        </svg>
                    </span>
                    <strong>Waitlist User</strong>
                </NavLink>
            </li>}
            {checkModuleAccess('popup_forms') &&<li>
                <NavLink className={"nav-link " + (popupForms  ? ' active' : '')} to="/popup-forms" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_wait_list"></use>
                        </svg>
                    </span>
                    <strong>Popup Forms</strong>
                </NavLink>
            </li>}
            {/* {checkModuleAccess('demo_page') &&<li>
                <NavLink className={"nav-link " + (popupForms  ? ' active' : '')} to="/demo-forms" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_wait_list"></use>
                        </svg>
                    </span>
                    <strong>Demo Forms</strong>
                </NavLink>
            </li>} */}
            {checkModuleAccess('blog')&&<li>
                <NavLink className={"nav-link " + (blogMenuActive  ? ' active' : '')} to="/blog/blog-list" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_bloglist"></use>
                        </svg>
                    </span>
                    <strong>Blog List</strong>
                </NavLink>
            </li>}

            {checkModuleAccess('users')&& <li>
                <div className={"nav-link nav-toggle " + (userMenuActive ? ' active' : '')}  
                onClick={(e)=>moreLink(e,'more1')}
                    >
                    <span>
                        <svg className="icon">
                            <use href="#icon_profile"></use>
                        </svg>
                    </span>
                    <strong>Users</strong>
                </div>
                <div className={"navmore-link " + (userMenuActive ? ' active' : '')}  id="more1">
                    <NavLink className="nav-link" to="/users/admin-list" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_alluser"></use>
                        </svg>
                    </span>
                    <strong>
                    All Users
                    </strong>
                    </NavLink>
                    <NavLink className="nav-link" to="/users/user-roles" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_roles"></use>
                        </svg>
                    </span>
                    <strong>Roles</strong>
                    </NavLink>
                </div>
            </li> }
            {checkModuleAccess('marketing_form') &&<li>
                <NavLink className="nav-link" to="/marketing-form" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_marketing"></use>
                        </svg>
                    </span>
                    <strong>Marketing Forms</strong>
                </NavLink>
            </li>}
            {checkModuleAccess('feedback') &&<li>
                <NavLink className="nav-link" to="/feedback" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_feedback"></use>
                        </svg>
                    </span>
                    <strong>Feedback</strong>
                </NavLink>
            </li>}
            {checkModuleAccess('free_ai_tools') &&<li>
                <NavLink className={"nav-link " + (freeAiToolsMenuActive  ? ' active' : '')} to="/free_ai_tools" state={state}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_free_ai_tools"></use>
                        </svg>
                    </span>
                    <strong>Free Ai Tools</strong>
                </NavLink>
            </li>}

            <li>
                <NavLink className="nav-link" state={state} to="/my-profile">
                    <span>
                        <svg className="icon">
                            <use href="#icon_setting"></use>
                        </svg>
                    </span>
                    <strong>Profile</strong>
                </NavLink>
            </li>
            <li>
                <NavLink className="nav-link" onClick={()=>{
                        localStorage.removeItem("admin_user_token");
                        localStorage.removeItem("admin_user_data");
                    }} to="/">
                    <span>
                        <svg className="icon">
                            <use href="#icon_logout"></use>
                        </svg>
                    </span>
                    <strong>Logout</strong>
                </NavLink>
            </li>
        </ul>
    </div>
    </>
  )
}

export default AsideBar