import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import moment from "moment";

function Token() {
  const [searchInput, setSearchInput] = useState("");
  const [loader, setloader] = useState(false);
  const [tokenList, settokenList] = useState([]);
  const [selectedToken, setselectedToken] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) {
      return "";
    }
    return moment(dateTimeString).format("D MMMM, YYYY | hh:mm A");
  };
  async function getTokenFn() {
    try {
      setloader(true);

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/adminapi/social/auth_setting`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });

      setloader(false);

      if (!response.data.error) {
        settokenList(response.data?.data || []);
      } else {
        settokenList([]);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      // setCurrentPage(0);
    } finally {
      setloader(false);
    }
  }
  const [submitting, setsubmitting] = useState(false);
  const [error, seterror] = useState("");
  async function postTokenFn() {
    try {
      setsubmitting(true);

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/adminapi/social/auth_update`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
        data: {
          id: selectedToken?.id,
          refresh_token: selectedToken?.refresh_token,
        },
      });

      setsubmitting(false);

      if (!response.data.error) {
        handleClose();
        getTokenFn();
      } else {
        seterror(response.data.message);
      }
    } catch (error) {
      seterror(error?.response?.data?.message || error?.message);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      // setCurrentPage(0);
    } finally {
      setsubmitting(false);
    }
  }
  useEffect(() => {
    getTokenFn();
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <h2 className="fw-700 fs-30 pt-4 pb-2">Token</h2>
            {/* <div className="d-flex py-4 space-x-4">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className="d-flex mt-2"
              >
                <input
                  type="text"
                  className="form-control rounded-pill"
                  id="searchinput"
                  placeholder="Token"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button type="submit" className="grd-btn rounded-pill mx-2">
                  Add
                </button>
              </form>
            </div> */}
            {/* <button
              onClick={() => {
                window.open(
                  "https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=454328450472-p9of5nnab6gopkd4dcme73s887a3op3d.apps.googleusercontent.com&redirect_uri=https://backend.dev-vison.infiniticube.in/googleapi/callback&scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/webmasters&access_type=offline&prompt=consent",
                  "_blank"
                );
              }}
              className="grd-btn rounded-pill w-[220px] space-x-4 cursor-pointer"
            >
              Generate Token
            </button> */}
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="normal-tbl">
            <table>
              <tr className="tr-bg">
                <th>Client Id</th>
                <th>Refresh Token</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th></th>
              </tr>
              {loader && (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Loader />
                  </td>
                </tr>
              )}
              <tr>
                {tokenList?.map((token) => (
                  <>
                    <td
                      style={{
                        width: "300px",
                        maxWidth: "300px",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      {token?.client_id}
                    </td>
                    <td
                      style={{
                        width: "300px",
                        maxWidth: "300px",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      {token?.refresh_token}
                    </td>
                    <td>{formatDateTime(token?.createdAt)}</td>
                    <td>{formatDateTime(token?.updatedAt)}</td>
                    <td>
                      <button
                        type="button"
                        className="arrow-btn "
                        onClick={() => {
                          setselectedToken(token);
                          handleShow();
                        }}
                      >
                        <svg className="icon fs-24">
                          <use href="#icon_edit"></use>
                        </svg>
                      </button>
                    </td>
                  </>
                ))}
              </tr>
            </table>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            type="text"
            className="form-control rounded-pill"
            id="searchinput"
            placeholder="Token"
            value={selectedToken?.refresh_token}
            onChange={(e) => {
              setselectedToken({
                ...selectedToken,
                refresh_token: e.target.value,
              });
            }}
          />
          <div className="w-100 justify-content-end mt-4">
            <button
              type="submit"
              className="grd-btn rounded-pill mx-2 w-100"
              disabled={submitting}
              onClick={() => {
                postTokenFn();
              }}
            >
              {submitting ? <Loader /> : "Update"}
            </button>
            <div>{error && <p className="text-danger">{error}</p>}</div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Token;
